export const PURCHASE_CONDITIONS = {
    ANY_PLAN: 1, // Que tenga cualquier plan
    WITH_PLAN: 2, // Que tenga un plan específico
    WITHOUT_PLAN: 3, // Que NO tenga el plan especificado
    // NO_PLAN: 4, Sin ningún plan
};

export const PAYMENT_PROVIDERS = {
    EPAYCO: {
        id: 2,
        name: 'epayco',
    },
    MERCADO_PAGO: {
        id: 3,
        name: 'mercado_pago',
    },
};

export const GOOGLE_ANALYTICS_EVENT_TYPES = {
    ADD_TO_CART: 'add_to_cart',
    ADD_PAYMENT_INFO: 'add_payment_info',
    BEGIN_CHECKOUT: 'begin_checkout',
    LOGIN: 'login',
    PURCHASE: 'purchase',
    SIGN_UP: 'sign_up',
    VIEW_ITEM_LIST: 'view_item_list',
    CUSTOM: {
        ADD_COUPON: 'coupon_add',
        HOME: 'home',
        REDIRECT_TO_EXTERNAL: 'redirect_to_external',
        REDIRECT_TO_PAYNILO: 'redirect_to_paynilo',
        RETURN_FROM_PAYNILO: 'return_from_paynilo',
        SET_PASSWORD: 'set_password',
        STEP_ONE_CHECK_EMAIL: 'step_one_check_email',
        STEP_TWO_SET_PERSONAL_INFO: 'step_two_set_personal_info',
        STEP_THREE_SET_BILLING_INFO: 'step_three_set_billing_info',
    },
};

export const ITEM_TYPES = {
    PHYSICAL: 1,
    DOWNLOADABLE: 2,
    VIRTUAL: 3,
    OTHER: 4,
};
