/* eslint-disable no-unused-vars */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable import/order */

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Inertia
import { App, plugin } from '@inertiajs/inertia-vue';

// Inertia Progress
import { InertiaProgress } from '@inertiajs/progress';

// Ziggy (rutas Laravel)
import route from 'ziggy-js';
import { Ziggy } from './ziggy';

// vee-validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';

// Mixin
import generalMixin from './mixins/generalMixin';
import googleDataLayerMixin from './mixins/googleDataLayerMixin';

// Vue
import Vue from 'vue';

// Vuex
import store from './store';

// Importaciones para arreglar error de Laravel Mix por etiqueta style en componentes Vue
import CssBase from 'css-loader/lib/css-base';
import AddStyles from 'style-loader/lib/addStyles';

// bootstrap.js
require('./bootstrap');

/*
|----------------------------------
|Vue js
|----------------------------------
*/
Vue.use(plugin);

/*
|----------------------------------
| Ziggy (rutas Laravel)
|----------------------------------
*/
Vue.mixin({
    methods: {
        route: (name, params, absolute = false) => route(name, params, absolute, Ziggy),
    },
});

/*
|----------------------------------
| Mixin General de la Aplicación
|----------------------------------
*/
Vue.mixin(generalMixin);

/*
|----------------------------------
| Mixin Google Tag Manager
|----------------------------------
*/
Vue.mixin(googleDataLayerMixin);

/*
|----------------------------------
|vee-validate
|----------------------------------
*/
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach((rule) => {

    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule], // assign message
    });

});

/*
|----------------------------------
|InertiaProgress
|----------------------------------
*/
const colors = JSON.parse(localStorage.getItem('colors'));
let primaryColor = '608e9e';

if (colors) {

    primaryColor = colors.primary;

}

InertiaProgress.init({

    delay: 0,
    color: primaryColor,

});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const el = document.getElementById('app');

new Vue({
    render: (h) => h(App, {
        props: {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: (name) => import(`./pages/${name}`).then((module) => module.default),
        },
    }),

    store,

}).$mount(el);
