    var Ziggy = {
        namedRoutes: {"home":{"uri":"\/","methods":["GET","HEAD"],"domain":null},"eb-healthcheck":{"uri":"eb-healthcheck","methods":["GET","HEAD"],"domain":null},"item.purchase":{"uri":"item\/purchase\/{id}","methods":["GET","HEAD"],"domain":null},"item.search":{"uri":"item\/search","methods":["GET","HEAD"],"domain":null},"item.category":{"uri":"item\/category\/{id?}","methods":["GET","HEAD"],"domain":null},"item.subcategory":{"uri":"item\/subcategory\/{id?}","methods":["GET","HEAD"],"domain":null},"item.detail":{"uri":"item\/{id?}","methods":["GET","HEAD"],"domain":null},"items.hidden":{"uri":"items\/hidden","methods":["GET","HEAD"],"domain":null},"shopping.cart":{"uri":"shopping-cart","methods":["GET","HEAD"],"domain":null},"shopping.checkout":{"uri":"shopping-checkout","methods":["GET","HEAD"],"domain":null},"shopping.status":{"uri":"payment-status","methods":["GET","HEAD"],"domain":null},"shopping.success":{"uri":"shopping-success","methods":["GET","HEAD"],"domain":null},"shopping.pending":{"uri":"shopping-pending","methods":["GET","HEAD"],"domain":null},"shopping.failure":{"uri":"shopping-failure","methods":["GET","HEAD"],"domain":null},"user.login":{"uri":"login","methods":["GET","HEAD"],"domain":null},"user.loginOption":{"uri":"login-option","methods":["GET","HEAD"],"domain":null},"user.register":{"uri":"user\/register","methods":["GET","HEAD"],"domain":null},"user.restorePassword":{"uri":"user\/restore-password","methods":["GET","HEAD"],"domain":null},"user.account":{"uri":"user\/account","methods":["GET","HEAD"],"domain":null},"user.setPassword":{"uri":"user\/set-password","methods":["GET","HEAD"],"domain":null},"user.dataVerification":{"uri":"user\/data-verification","methods":["GET","HEAD"],"domain":null}},
        baseUrl: 'http://app.localniloecommerce.com/',
        baseProtocol: 'http',
        baseDomain: 'app.localniloecommerce.com',
        basePort: false,
        defaultParameters: []
    };

    if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
        for (var name in window.Ziggy.namedRoutes) {
            Ziggy.namedRoutes[name] = window.Ziggy.namedRoutes[name];
        }
    }

    export {
        Ziggy
    }
