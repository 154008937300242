import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({

    state: {

        user: null, // uuid, name, email,
        userItemInterests: [],
        shoppingCart: [],

        pickUpInStore: false,

        shipping: {
            value: 0,
            min_purchase: 0,
            estimated_delivery: '',
            pick_up_in_store: false,
        },

        coupon: null,

        sindykIntegration: false,
        isCambioBookstore: false,

        templateId: 1,

    },

    getters: {

        getUserName: (state) => {

            if (state.user) {

                return state.user.name;

            }

            return null;

        },

        getUserEmail: (state) => {

            if (state.user) {

                return state.user.email;

            }

            return null;

        },

        getUserUuid: (state) => {

            if (state.user && state.user.uuid) {

                return state.user.uuid;

            }

            return null;

        },

        getUserPhoto: (state) => {

            if (state.user) {

                return state.user.photo;

            }

            return null;

        },

        getItemIsInterest: (state) => (itemId) => {

            if (!state.userItemInterests || state.userItemInterests.length == 0) {

                return false;

            }

            const exists = state.userItemInterests.find((item) => item.id == itemId);

            return !!exists;

        },

        getShoppingCart: (state) => JSON.parse(JSON.stringify(state.shoppingCart)),

        getShoppingCartCount: (state) => {

            if (state.shoppingCart.length == 0) {

                return 0;

            }

            const totalItems = state.shoppingCart.reduce((acum, item) => acum + Number(item.quantity), 0);

            return totalItems;

        },

        getShoppingCartItemData: (state) => (itemId) => {

            if (state.shoppingCart.length == 0) {

                return null;

            }

            return state.shoppingCart.find((item) => item.id == itemId);

        },

        getShoppingCartTotal: (state) => {

            if (state.shoppingCart.length == 0) {

                return 0;

            }

            const total = state.shoppingCart.reduce((acum, item) => acum + (Number(item.price) * Number(item.quantity)), 0);

            return total;

        },

        getShippingValue: (state, getters) => {

            // Si recoge en la tienda
            if (state.pickUpInStore) {

                return 0;

            }

            // Si tiene envíos por compras superiores a un valor
            if (state.shipping.value > 0 && state.shipping.min_purchase > 0) {

                if (getters.getShoppingCartTotal >= state.shipping.min_purchase) {

                    return 0;

                }

            }

            // Si todos los productos son virtuales no cobro envío
            const isNotVirtual = state.shoppingCart.find((item) => !item.is_virtual);
            if (state.shoppingCart.length > 0 && !isNotVirtual) {

                return 0;

            }

            return state.shipping.value;

        },

        getFreeShippingMinPurchase: (state) => {

            if (state.shipping.value > 0 && state.shipping.min_purchase > 0) {

                return state.shipping.min_purchase;

            }

            return 0;

        },

        getShippingDeliveryTime: (state) => state.shipping.estimated_delivery,

        getPickUpInStore: (state) => state.shipping.pick_up_in_store,

        getCouponCode: (state) => (state.coupon && state.coupon.coupon ? state.coupon.coupon : ''),

        getCoupon: (state) => state.coupon,

        getSindykIntegration: (state) => state.sindykIntegration,

        getTemplateId: (state) => state.templateId,

        getItemsForDataLayer: (state) => {

            if (state.shoppingCart.length == 0) {

                return [];

            }

            const items = state.shoppingCart.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                price: item.price,
                quantity: item.quantity,
            }));

            return items;

        },

        getIsVirtualPurchase: (state) => !state.shoppingCart.find((item) => !item.is_virtual),

        getIsCambioBookstore: (state) => state.isCambioBookstore,

    },

    mutations: {

        setUser(state, payload) {

            state.user = payload;

        },

        setUserName(state, payload) {

            if (state.user) {

                state.user.name = payload;

            }

        },

        setUserItemInterests(state, payload) {

            state.userItemInterests = payload;

        },

        setShoppingCart(state, payload) {

            state.shoppingCart = payload;

        },

        setShipping(state, payload) {

            state.shipping = { ...payload };

        },

        setIsCambioBookstore(state, payload) {

            state.isCambioBookstore = payload;

        },

        setPickUpInStore(state, payload) {

            state.pickUpInStore = payload;

        },

        setCoupon(state, payload) {

            state.coupon = payload;

        },

        setSindykIntegration(state, payload) {

            state.sindykIntegration = payload;

        },

        setTemplateId(state, payload) {

            state.templateId = payload;

        },

    },

    actions: {

        storeUser({ commit }, user) {

            const itemInterests = user.item_interests;

            delete user.item_interests;

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('user_item_interests', JSON.stringify(itemInterests));

            commit('setUser', user);
            commit('setUserItemInterests', itemInterests);

        },

        storeUserName({ commit }, userName) {

            const user = JSON.parse(localStorage.getItem('user'));

            if (user) {

                user.name = userName;

                localStorage.setItem('user', JSON.stringify(user));

            }

            commit('setUserName', userName);

        },

        checkUserSession({ commit }, sessionActive) {

            if (sessionActive) {

                const user = JSON.parse(localStorage.getItem('user'));
                const itemInterests = JSON.parse(localStorage.getItem('user_item_interests'));

                commit('setUser', user);
                commit('setUserItemInterests', itemInterests);

                if (!user) {

                    axios
                        .post('/user/logout').then(() => {

                            window.location = '/';

                        });

                }

            } else {

                localStorage.removeItem('user');
                localStorage.removeItem('user_item_interests');

                commit('setUser', null);
                commit('setUserItemInterests', []);

            }

        },

        userLogout({ commit }) {

            localStorage.removeItem('user');
            localStorage.removeItem('user_item_interests');

            commit('setUser', null);
            commit('setUserItemInterests', []);

        },

        storeUserItemInterest({ commit }, itemId) {

            const itemInterests = JSON.parse(localStorage.getItem('user_item_interests'));

            itemInterests.push({ id: itemId });

            localStorage.setItem('user_item_interests', JSON.stringify(itemInterests));

            commit('setUserItemInterests', itemInterests);

        },

        removeUserItemInterest({ commit }, itemId) {

            let itemInterests = JSON.parse(localStorage.getItem('user_item_interests'));

            itemInterests = itemInterests.filter((item) => item.id != itemId);

            localStorage.setItem('user_item_interests', JSON.stringify(itemInterests));

            commit('setUserItemInterests', itemInterests);

        },

        addItemToShoppingCart({ commit, state }, item) {

            let cart = [];

            item.is_virtual = item.is_virtual ?? false;

            // Si no hay items agregados o hay integración con Sindyk
            if (state.shoppingCart.length == 0 || (state.sindykIntegration && !state.isCambioBookstore)) {

                cart.push(item);

                commit('setShoppingCart', cart);

            } else {

                cart = state.shoppingCart;

                // Valido la existencia del item en el carrito de compra
                const index = cart.findIndex((i) => i.id == item.id);

                if (index < 0) { // Si no existe lo agrego

                    cart.push(item);

                    commit('setShoppingCart', cart);

                } else { // Si existe modifico la cantidad

                    cart[index].quantity += item.quantity;

                    commit('setShoppingCart', cart);

                }

            }

            localStorage.setItem('shopping_cart', JSON.stringify(cart));

        },

        updateShoppingCart({ commit }, cart) {

            const cleanCart = cart.map((item) => {

                delete item.quantity_limit;

                return item;

            });

            commit('setShoppingCart', cleanCart);

            localStorage.setItem('shopping_cart', JSON.stringify(cleanCart));

        },

        removeItemFromShoppingCart({ commit, state }, itemId) {

            const cart = state.shoppingCart.filter((item) => item.id != itemId);

            commit('setShoppingCart', cart);

            localStorage.setItem('shopping_cart', JSON.stringify(cart));

        },

        clearShoppingCart({ commit }) {

            commit('setShoppingCart', []);

            localStorage.setItem('shopping_cart', JSON.stringify([]));

        },

        updateDiscountInShoppingCart({ commit, state }) {

            const cart = state.shoppingCart.map((item) => {

                item.discount_percentage = 0;

                if (state.coupon && state.coupon.discount_type == 2 && (!state.coupon.item_id || state.coupon.item_id == item.id)) {

                    item.discount_percentage = state.coupon.value;

                }

                return item;

            });

            commit('setShoppingCart', cart);

            localStorage.setItem('shopping_cart', JSON.stringify(cart));

        },

        checkShoppingCart({ commit }) {

            const cart = JSON.parse(localStorage.getItem('shopping_cart'));

            if (cart) {

                commit('setShoppingCart', cart);

            }

        },

        storeShipping({ commit }) {

            const shipping = JSON.parse(localStorage.getItem('shipping'));

            if (shipping) {

                commit('setShipping', shipping);

            }

        },

        storeIsCambioBookstore({ commit }) {

            const status = JSON.parse(localStorage.getItem('is_cambio_bookstore'));

            if (status) {

                commit('setIsCambioBookstore', status);

            }

        },

        storePickUpInStore({ commit }, value) {

            commit('setPickUpInStore', value);

        },

        storeCoupon({ commit }, value) {

            localStorage.setItem('coupon', JSON.stringify(value));

            commit('setCoupon', value);

        },

        checkCoupon({ commit }) {

            const coupon = JSON.parse(localStorage.getItem('coupon'));

            commit('setCoupon', coupon);

        },

        removeCoupon({ commit }) {

            localStorage.removeItem('coupon');

            commit('setCoupon', null);

        },

        checkSindykIntegration({ commit }) {

            const platforms = JSON.parse(localStorage.getItem('platforms'));

            if (platforms && platforms.includes('sindyk')) {

                commit('setSindykIntegration', true);

            }

        },

        checkTemplateId({ commit }) {

            const template = JSON.parse(localStorage.getItem('template_id'));

            if (template) {

                commit('setTemplateId', template);

            }

        },

    },

});

export default store;
