import { mapGetters } from 'vuex';
import pushDataLayerEvent from '../google/dataLayerEvents';
import { GOOGLE_ANALYTICS_EVENT_TYPES } from '../globlas';

const googleDataLayerMixin = {

    computed: {

        ...mapGetters([

            'getItemsForDataLayer',
            'getShoppingCartTotal',
            'getCouponCode',

        ]),

    },

    methods: {

        addDataLayerEvent(type, params) {

            const dataLayerParams = this.buildParameters(type, params);

            pushDataLayerEvent(type, dataLayerParams);

        },

        buildParameters(type, params) {

            if (params) {

                return params;

            }

            let dataLayerParams = null;

            if (
                [
                    GOOGLE_ANALYTICS_EVENT_TYPES.ADD_PAYMENT_INFO,
                    GOOGLE_ANALYTICS_EVENT_TYPES.BEGIN_CHECKOUT,
                    GOOGLE_ANALYTICS_EVENT_TYPES.PURCHASE,
                ].includes(type)
            ) {

                dataLayerParams = {
                    value: this.getShoppingCartTotal,
                    coupon: this.getCouponCode,
                    items: this.getItemsForDataLayer,
                };

            }

            return dataLayerParams;

        },

    },

};

export default googleDataLayerMixin;
