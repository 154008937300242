import { GOOGLE_ANALYTICS_EVENT_TYPES } from '../globlas';
// import { createUUID } from '../utils';

const EVENT_TYPES = GOOGLE_ANALYTICS_EVENT_TYPES;

const simpleEvent = (type) => ({
    event: type,
});

const simpleEventWithParams = (type, params) => ({
    event: type,
    ...params,
});

const itemsWithTotalAndCouponEvent = (type, params) => {

    window.dataLayer.push({ ecommerce: null });

    return {

        event: type,
        ecommerce: {
            currency: 'COP',
            value: params.value,
            coupon: params.coupon,
            items: params.items,
        },

    };

};

const itemsWithTotalEvent = (type, params) => {

    window.dataLayer.push({ ecommerce: null });

    return {

        event: type,
        ecommerce: {
            currency: 'COP',
            value: params.value,
            items: params.items,
        },

    };

};

// const purchaseEvent = (type, params) => {

//     window.dataLayer.push({ ecommerce: null });

//     return {

//         event: type,
//         ecommerce: {
//             currency: 'COP',
//             transaction_id: createUUID(),
//             value: params.value,
//             items: params.items,
//         },

//     };

// };

const itemListEvent = (type, params) => {

    window.dataLayer.push({ ecommerce: null });

    return {

        event: type,
        ecommerce: {
            item_list_id: params.itemListId,
            item_list_name: params.itemListName,
            items: params.items,
        },

    };

};

const eventTypesDict = {
    [EVENT_TYPES.ADD_TO_CART]: itemsWithTotalEvent,
    // [EVENT_TYPES.ADD_PAYMENT_INFO]: itemsWithTotalAndCouponEvent,
    [EVENT_TYPES.BEGIN_CHECKOUT]: itemsWithTotalAndCouponEvent,
    // [EVENT_TYPES.PURCHASE]: purchaseEvent,
    [EVENT_TYPES.VIEW_ITEM_LIST]: itemListEvent,
    [EVENT_TYPES.LOGIN]: simpleEvent,
    [EVENT_TYPES.SIGN_UP]: simpleEvent,
    [EVENT_TYPES.CUSTOM.STEP_ONE_CHECK_EMAIL]: simpleEvent,
    [EVENT_TYPES.CUSTOM.STEP_TWO_SET_PERSONAL_INFO]: simpleEvent,
    [EVENT_TYPES.CUSTOM.STEP_THREE_SET_BILLING_INFO]: simpleEvent,
    [EVENT_TYPES.CUSTOM.ADD_COUPON]: simpleEventWithParams,
    [EVENT_TYPES.CUSTOM.SET_PASSWORD]: simpleEvent,
    [EVENT_TYPES.CUSTOM.REDIRECT_TO_EXTERNAL]: simpleEventWithParams,
    [EVENT_TYPES.CUSTOM.REDIRECT_TO_PAYNILO]: simpleEvent,
    [EVENT_TYPES.CUSTOM.RETURN_FROM_PAYNILO]: simpleEvent,
    [EVENT_TYPES.CUSTOM.HOME]: simpleEvent,
};

const callEventsDictFn = (type, params) => eventTypesDict[type](type, params);

const pushDataLayerEvent = (type, params) => {

    if (window.dataLayer && window.dataLayer.length > 0) {

        const event = callEventsDictFn(type, params);

        window.dataLayer.push(event);

    }

};

export default pushDataLayerEvent;
