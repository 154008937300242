const mobileCheckMixin = {

    data() {

        return {

            isMobile: false,

        };

    },

    created() {

        this.mobileCheck();

    },

    methods: {

        mobileCheck() {

            if ($(window).width() > 768) {

                this.isMobile = false;

            } else {

                this.isMobile = true;

            }

        },

    },

};

export default mobileCheckMixin;
